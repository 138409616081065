import styled, {css} from 'styled-components';
import img from '/public/images/behaviorChallenges/InviteAmigoPopUp.png';
import { StyledBody, StyledModal } from '../../ChallengeDetailsV2/StartChallengePopUp/styles';

const ModalBody = styled(StyledBody)`
  width: 100%;
  height: auto;
  border-radius: 12px;
  padding: ${({padding}) => padding ? padding: '0'};
`;
const ModalStyled = styled(StyledModal)`
  .modal-dialog {
    transform: translate(-50%, -50%);
    .modal-header {
      padding: 10px;
      border-bottom: none;
    }
    @media (max-width: 500px){
      width: 100%;
      padding: 10px;
      margin: 0px;
    }
  }
  .modal-content{
    width: 600px;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 500px){
      width: 100%;
    }
    .modal-header .close {
      margin-top: 8px;
    }
  }
  @media (max-width: 500px){
    padding: 0px;
  }
`;
const Reactangle = styled.div`
  width: 100%;
  > div{
    width: 85%;
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    margin: 30px auto;
    @media(max-width: 600px){
      width: 100%;
    }
  }
`;

const InviteButton = styled.div`
  width: ${({width}) => width ? 'auto' : '100%'};
  display: flex;
  align-items: center;
  justify-content: ${({plusAmigo}) => plusAmigo ? 'flex-end' : 'space-around'};
  margin: ${({background, plusAmigo}) => background && !plusAmigo ? '20px 0' : '0px'};
  .button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 50px;
    border-color: #FFFFFF;
    background: ${({background, plusAmigo}) => plusAmigo ? plusAmigo : background ? '#159fc9' : 'linear-gradient(0deg, #FFFFFF, #FFFFFF)'};
    border-radius: 25px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    color: ${({background, plusAmigo}) => background || plusAmigo ? '#FFFFFF' : '#484856'};
    outline: none;
    cursor: pointer;
  }
  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  @media(max-width: 600px){
    width: 100%;
  }
`;
const EmployeeList = styled.div`
  width: 410px;
  height: auto;
  max-height: 530px;
  background-color: white;
  border-radius: 6px;
  margin: 0 auto;
  padding: ${({padding}) => padding ? "20px": '1px'};
  text-align: ${({padding}) => padding && 'center'}
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }
  ::-webkit-scrollbar-thumb {
   background: #c7c8c7;
   cursor: pointer;
   border-radius: 2.5px;
 }
  ::-webkit-scrollbar-track {
   background: #fff;
   border-radius: 2.5px;
 } 
  @media(max-width: 500px){
    width: 100%;
  }
`;

const InviteCheckBox = styled.span`
  position: relative;
  &::before {
    content:'';
    -webkit-appearance: none;
    background-color: ${({checked}) => checked ? '#2FD3BE' : 'transparent'};
    border: 2px solid #2FD3BE;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  &::after {
    content: "";
    display: ${({checked}) => checked ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #FFFFFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 4px;
    left: 30%;
  }
`;
const CheckMarkContainer = styled.div`
  display: flex;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  @media(max-width: 400px){
    float: right;
  }
`;
const Employee = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #F6F6F8;
  .profile{
    width: 20%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .nameAndWellnessInterest{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkbox{
    width: 20%;
  }
`;

const CalendarHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: #005C87;
  border-radius: 6px 6px 0 0;
  justify-content:space-between;
  padding:15px;
  .title {
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#FFF;
    display: flex;
    justify-content: center;
  }
  .filter-dropdown{
    width:135px;
  }
  .closeButton{
    width:24px;
    height:24px;
    cursor:pointer;
    >img{
      height:100%;
      width:100%;
    }
  }
 
`
const ParticipantsList = styled.div`
  width: 100%;
  height: auto;
  max-height: 530px;
  background-color: white;
  border-radius: 6px;
  margin: 0 auto;
  padding: ${({padding}) => padding ? "20px": '1px'};
  text-align: ${({padding}) => padding && 'center'}
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  border: 1px solid #005C8799;
  ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }
  ::-webkit-scrollbar-thumb {
   background: #c7c8c7;
   cursor: pointer;
   border-radius: 2.5px;
 }
  ::-webkit-scrollbar-track {
   background: #fff;
   border-radius: 2.5px;
 } 
  @media(max-width: 500px){
    width: 100%;
  }
`;

const Participant = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-top: ${({border}) => border ? "1px solid #005C8799": ''};
  .profile{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .nameAndWellnessInterest{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkbox{
    display:flex;
    margin: auto;
    margin-right: 25px;
  }
`;

const ImageContainer = styled.div`
  min-width: ${({ active, width }) => width ? width : active ? '92px' : '52px'};
  height: ${({ active, width }) => width ? width : active ? '92px' : '52px'};;
  border-radius: ${({ active }) => active ? '50%' : '6px'};
  background-color: #e8e7e7;
  overflow: hidden;
  float: left;
  margin: 0 15px 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > img {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 500px) {
    display: block;
    float: none;
    margin: 0 auto;
  }
`;

const EmployeeInterestContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '15px'};
    left: ${({newPosition}) => newPosition ? '51px' : 'none'};
    bottom: ${({newPosition}) => newPosition ? '6px' : 'none'};
    & > div {
      width: 28px;
      height: 28px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      background: linear-gradient(360deg, rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
      ${({active}) => active && css`
      background: linear-gradient(360deg, rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
      `}
      background-color: ${({deleted, active}) => deleted && ' #F6F6F8' || !active && ' #F6F6F8'};
      & > img {
        width: 18px;
        height: 18px;
        border-radius: 6px;
      }
    }
    @media (max-width: 500px) {
        justify-content: center;
        padding: 7px 0;
    }
`;

const InviteButtonWrapper = styled.div`
width:100%;
display:flex;
border: 1px solid #005C874D;
padding:0 25px 0 25px;
.button{
width: ${({ width }) => (width ? "48%" : "100%")};
font-size: 18px;
font-size: ${({ font }) => (font ? font : "18px")};
line-height: 24px;
font-family: rubik-medium;
color: ${({ disabled }) => (disabled ? 'white' : "white")};
padding: 13px 8px 13px 8px;
padding: ${({ padding }) => (padding ? padding : "13px 8px 13px 8px")};
display: flex;
margin-bottom: ${({ margin }) => (margin ? margin : "15px")};
justify-content: center;
border-radius: 3px;
cursor: ${({ cursor }) => (cursor ? "" : "pointer")};
background: ${({ disabled }) =>disabled ? '#005C874D' : "#005C87"};
box-shadow: ${({ disabled }) => (disabled ? '' : "0px 8px 24px 0px #005C8780")};
}
`;

const StyledInput = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 50px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color:${({ color }) => color ? color : 'rgba(0, 47, 71, 1)'};
  border:${({ border }) => border ? border : '1px solid #cdcbcb'};
  background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &:active{
    border:${({ border }) => border ? border : '1px solid #9c9c9c'};
  }
  &::placeholder {
    font-size: 16px;
    color: #9c9c9c;
    color:${({ pColor }) => pColor ? pColor : '#9c9c9c'};
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus, &:active {
    outline: none;
  }
  
`;

const InputWrapper = styled.div`
width:100%;
padding: 20px 25px 0px 25px;

`;
export { ModalStyled, ModalBody, Reactangle, InviteButton, EmployeeList, InviteCheckBox, CheckMarkContainer, Employee, CalendarHeader, ParticipantsList, Participant, ImageContainer, EmployeeInterestContainer, InviteButtonWrapper, StyledInput, InputWrapper}