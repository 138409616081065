import React, { Component } from "react";
import {
  Reactangle
} from "./eventstyles";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  inviteUserForJoiningEvents,
  getUserFriends
} from "../../../redux/actions";
import PropTypes from "prop-types";
import {
  EmployeeName
} from "../../PeopleListViewV2/styles";
import { ImageUrl } from "../../../utils/constants";
import { COMPANY_NAME } from "../../../utils/config";
import { isNull } from "lodash";
import {
  ModalBody,
  ParticipantsList,
  Participant,
  ImageContainer,
  EmployeeInterestContainer,
  StyledInput,
  InputWrapper,
  InviteButtonWrapper
} from './styles';
import { CustomRadioButton } from "../../CreateEvent/style";
import { StyledModal } from "../../PeopleHomeV2/ManageBuddies/styles";

class InviteAmigoPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amigosList: [],
      uninviteUids: [],
      searchedPeopleArray:[],
      searchInput: null,
    };
  }

  componentDidMount() {
    const { getUserFriends, userId } = this.props;
    getUserFriends(userId);
  }

  inviteAmigo = (amigo, alreadyInvited) => {
    const { amigosList, uninviteUids } = this.state;
    if (alreadyInvited) {
      if (uninviteUids.includes(amigo)) {
        let index = uninviteUids.findIndex((item) => item === amigo);
        if (index > -1) {
          uninviteUids.splice(index, 1);
        }
        amigosList.push(amigo);
      } else {
        uninviteUids.push(amigo);
        let index = amigosList.findIndex((item) => item === amigo);
        if (index > -1) {
          amigosList.splice(index, 1);
        }
      }
    } else if (amigosList.includes(amigo)) {
      let index = amigosList.findIndex((item) => item === amigo);
      if (index > -1) {
        amigosList.splice(index, 1);
      }
    } else {
      amigosList.push(amigo);
    }
    this.setState({
      amigosList: amigosList,
      uninviteUids: uninviteUids,
    });
  };
  selectAllAmigos = (e) => {
    e.preventDefault();
    let invitedAmigos = [];
    let uninviteUid = [];
    const { inviteAmigosList } = this.props;
    if (this.state.amigosList.length === inviteAmigosList?.length) {
      invitedAmigos = [];
      inviteAmigosList.map((amigo) => {
        amigo.is_invited && uninviteUid.push(amigo.friend_id);
      });
    } else {
      inviteAmigosList.map((amigo) => {
        invitedAmigos.push(amigo.friend_id);
      });
    }
    this.setState({
      amigosList: invitedAmigos,
      uninviteUids: uninviteUid,
    });
  };
  redirectChallengeOnHide = () => {
    const {
      onHide,
      history,
      eventId,
      plusAmigoButton
    } = this.props;
    plusAmigoButton ? null : history.push(`/events/${eventId}`);
    onHide();
  };
  onInvite = () => {
    const { amigosList, uninviteUids } = this.state;
    const {
      eventId,
      inviteUserForJoiningEvents,
      userId,
      plusAmigoButton,
      history,
    } = this.props;
    let object = {
      user_ids: amigosList,
      uninvite_uids: uninviteUids,
      event_id: eventId,
      is_attending: 2,
    };
    inviteUserForJoiningEvents(object, userId);
    this.setState({ amigosList: [], uninviteUids: [] });
    plusAmigoButton ? null : history.push(`/events/${eventId}`);
  };
  componentDidUpdate(prevProps) {
    const { inviteAmigosList } = this.props;
    if (
      prevProps.inviteAmigosList !== inviteAmigosList &&
      inviteAmigosList?.length > 0 &&
      !isNull(inviteAmigosList[0])
    ) {
      const { amigosList } = this.state;
      inviteAmigosList.map((data) => {
        if (data.is_invited && !amigosList.includes(data.friend_id))
          amigosList.push(data.friend_id);
      });
      this.setState({ amigosList: amigosList });
    }
  }

  onChangeInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, () => {
      this.searchArray(this.state.searchInput, this.props.inviteAmigosList);
    })
  };

  searchArray = (input, array) => {
    const results = array?.length && array.filter((item) => item.fullname.toLowerCase().includes(input.toLowerCase()));

    this.setState({
      searchedPeopleArray: results,
    });
  };

  fallBack = (e) => {
    e.target.src = '/public/images/fallback.png'
  }

  render() {
    const { showModal, inviteAmigosList } = this.props;
    const { amigosList, searchInput, searchedPeopleArray } = this.state;
    let showListStatus = inviteAmigosList?.length > 0&&!isNull(inviteAmigosList[0])?1:0;
    const invitePeopleList = searchedPeopleArray&&searchInput?searchedPeopleArray: inviteAmigosList; 
    return (
      <StyledModal
        show={showModal}
        backdropClassName="drop-back"
        onHide={() => this.redirectChallengeOnHide()}
        padding="25px"
        height="auto"
      >
        <Modal.Header style={{background:"#005c87",borderRadius:"6px 6px 0px 0px",display:'flex',justifyContent:"space-between",alignItems:"center"}}>  
          <Reactangle style={{height:"50px",paddingTop: "13px",textAlign: "left",paddingLeft: "10px",margin:"0px"}}>
            <div style={{margin:"0px",textALign:"left"}}>{`Select ${
              COMPANY_NAME === "Woliba" ? "Buddies" : "Amigos"
            } To Invite For Events`}</div>
            {/* {showListStatus && (
              <InviteButton>
                <div
                  className={"button"}
                  onClick={(e) => this.selectAllAmigos(e)}
                >{`${
                    amigosList.length === invitePeopleList?.length ? "DE" : ""
                  }SELECT ALL`}</div>
                <div className={"button"} onClick={() => this.onInvite()}>
                  INVITE
                </div>
              </InviteButton>
            )} */}
          </Reactangle>
          <div><img src='/public/images/new_close_icon.png' style={{width:"20px",height:"20px",cursor:"pointer"}}  onClick={() => this.redirectChallengeOnHide()} /></div>
        </Modal.Header>
        <InputWrapper><StyledInput
          placeholder={("Search Buddies")}
          name="searchInput"
          onChange={inviteAmigosList?.length>0&&!isNull(inviteAmigosList[0])? (e)=>{this.onChangeInput(e)}:null}
          value={searchInput}
          maxLength="100"
          bgColor="#005C870D"
          pColor="#005C8799"
          border="1px solid #005C870D"
          color="#005C87"
        /></InputWrapper>
        <ModalBody>          
          <ParticipantsList
            padding={
              invitePeopleList?.length === 0 || isNull(invitePeopleList)
                ? 1
                : 0
            }
            style={{width:"100%"}}
          >
            { showListStatus ? (
              invitePeopleList?.length>0?  invitePeopleList.map((employee, index) => (
                <Participant key={index} border={index === 0 ? 0 : "1"}>
                  <div className={"profile"}>
                    <ImageContainer width={"60px"}>
                      <img onError={this.fallBack} src={`${ImageUrl}/${employee.profile_image}`} />
                    </ImageContainer>
                  </div>
                  <div className={"nameAndWellnessInterest"}>
                    <EmployeeName color="#282C37" inactive>
                      {employee.fullname}
                    </EmployeeName>
                    <EmployeeInterestContainer marginLeft={"0px"}>
                      {employee.interests &&
                        employee.interests.map(
                          (Wellness, index) =>
                            index < 5 && (
                              <div key={index}>
                                <img
                                  src={`${ImageUrl}/${Wellness.interest_icon}`}
                                />
                              </div>
                            )
                        )}
                    </EmployeeInterestContainer>
                  </div>
                  <div className={"checkbox"} >
                    <CustomRadioButton
                      width="30px"
                      onClick={employee.is_invited?null: () =>
                        this.inviteAmigo(
                          employee.friend_id,
                          employee.is_invited
                        )
                      }
                      style={{opacity:employee.is_invited&&"50%", cursor:employee.is_invited?"not-allowed":"pointer"}}
                    >
                      {" "}
                      {amigosList.includes(employee.friend_id) && (
                        <img src={ImageUrl+"/ChallengeDetailsScreen/completeCheck.png"} alt="no-image"/>
                      )}{" "}
                    </CustomRadioButton>
                  </div>
                </Participant>
              )):<span>{`No ${COMPANY_NAME === "Woliba" ? "Buddies" : "Amigos"} found`}</span>
            ) : (
              <div style={{padding:"30px", borderRadius:"6px",color:"#005c87", display:"flex", justifyContent:"center"}}>
                {`No ${COMPANY_NAME === "Woliba" ? "Buddies" : "Amigos"} found`}
              </div>
            )}
          </ParticipantsList>
        </ModalBody>
        <InviteButtonWrapper 
          style={{paddingTop:'14px'}}
          disabled={
            invitePeopleList?.length === 0 ||isNull(invitePeopleList) ||amigosList?.length === 0
          }
          onClick={() =>
            showListStatus && amigosList?.length && this.onInvite()
          }
        >
          <div className="button">INVITE BUDDIES</div>
        </InviteButtonWrapper>
      </StyledModal>
    );
  }
}
InviteAmigoPopUp.propTypes = {
  userId: PropTypes.number,
  showModal: PropTypes.bool,
  inviteUserForJoiningEvents: PropTypes.func,
  onHide: PropTypes.func,
  inviteAmigosList: PropTypes.array,
  eventId: PropTypes.number,
  plusAmigoButton: PropTypes.number,
  history: PropTypes.object,
  getUserFriends: PropTypes.func
};
const mapStateToProps = (state) => ({
  inviteAmigosList: state.social.friendList,
});
const mapDispatchToProps = (dispatch) => ({
  inviteUserForJoiningEvents: (data) =>dispatch(inviteUserForJoiningEvents(data)),
  getUserFriends: (userId) => dispatch(getUserFriends(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InviteAmigoPopUp);
